import React from "react";
import Seo from "../../components/seo";
import Layout from "../../components/layout";

import useProjects from "../../hooks/useProjects";
import ContentWrapper from "../../components/contentWrapper";
import Typography from "../../components/typography";
import ProjectCard from "../../components/projectCard";
import * as s from "./styles.module.scss";
import { Project } from "../../models/project";

const WorkPage = () => {
  const projects: Project[] = useProjects();

  return (
    <Layout background>
      <Seo title="Work" description="Some of my work samples" />
      <ContentWrapper>
        <div className={s.work}>
          <header className={s.work__header}>
            <Typography variant="h1" gutterBottom>
              Some Work Samples
            </Typography>
            <Typography variant="h4" gutterBottom>
              A few projects that I've worked on in the last coupl'a years...
            </Typography>
            <Typography variant="small" gutterBottom>
              If you wanna see some more work, reach out. I'll send over a PDF
              portfolio.
            </Typography>
          </header>
          <div className={s.workGrid}>
            {projects.map((project: Project, i: number) => {
              return (
                <ProjectCard
                  thumbnail={project.node.projectThumbnail}
                  slug={project.node.slug}
                  name={project.node.projectName}
                  key={i}
                />
              );
            })}
          </div>
        </div>
      </ContentWrapper>
    </Layout>
  );
};

export default WorkPage;
